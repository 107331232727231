export default {
    name: 'QAExplorerQuestion',
    props: {
        value: {
            type: String,
        },
        isInput: {
            type: Boolean,
        }
    },
    data () {
        return {
            question: this.value
        }
    },
    methods: {
        handleInput () {
            this.$emit('input', this.question)
        }
    },
    watch: {
        value: function(newValue) {
            this.question = newValue;
        }
    }
}
