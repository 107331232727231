function setActiveBarProperties(el, tab) {
    const currentTabHtmlElement = el.querySelectorAll('.qa-explorer-tab-wrapper').item(tab);
    if (currentTabHtmlElement) {
        return {
            activeBarWidth: tab === 0 ? currentTabHtmlElement.clientWidth - 12 : currentTabHtmlElement.clientWidth - 24,
            activeBarX: tab === 0 ? 0 : currentTabHtmlElement.offsetLeft + 12,
        }
    }
}

export default {
    name: 'QAExplorerTabs',
    data: function () {
        return {
            currentTab: 0,
            activeBarWidth: 0,
            activeBarX: 0,
            labelsPerTab: [
                'Saisie libre',
                '1er exemple',
                '2e exemple',
                '3e exemple',
            ],
            tabs: [0, 1, 2, 3],
        };
    },
    mounted: function() {
        const {activeBarWidth, activeBarX} = setActiveBarProperties(this.$el, 0);
        this.activeBarWidth = activeBarWidth;
        this.activeBarX = activeBarX;
    },
    methods: {
        handleClick: function (tab) {
            this.currentTab = tab;
            const {activeBarWidth, activeBarX} = setActiveBarProperties(this.$el, tab);
            this.activeBarWidth = activeBarWidth;
            this.activeBarX = activeBarX;
            this.$emit('tabChange', tab);
        },
    },
};
