import axios from 'axios'
import QAAnalysis from "../QAAnalysis/QAAnalysis.vue";
import QAExplorerHeader from "../QAExplorerHeader/QAExplorerHeader.vue";
import QAExplorerParagraph from "../QAExplorerParagraph/QAExplorerParagraph.vue";
import QAExplorerQuestion from "../QAExplorerQuestion/QAExplorerQuestion.vue";
import QAExplorerTabs from "../QAExplorerTabs/QAExplorerTabs.vue";

export default {
    name: 'QAExplorer',
    components: {
        QAExplorerHeader,
        QAExplorerTabs,
        QAExplorerParagraph,
        QAExplorerQuestion,
        QAAnalysis,
    },
    data: function () {
        return {
            loading: false,
            mainContentHeight: 0,
            question: '',
            paragraph: '',
            qaResults: '',
            listOfQuestions: [],
            questionsPerTab: [
                '',
                'À quelle fréquence l\'employeur doit-il organiser des élections ?',
                'Où sont détaillées les Heures Creuses ?',
                'Quels facteurs peuvent jouer sur la capacité d\'un propriétaire de changer le prix du loyer ?',
            ],
            paragraphsPerTab: [
                '',
                'Lorsque le seuil de onze salariés a été franchi dans les conditions prévues ' +
                'au deuxième alinéa de l\'article L. 2311-2, l\'employeur informe le personnel tous les quatre ' +
                'ans de l\'organisation des élections par tout moyen permettant de conférer date certaine à cette ' +
                'information. Le document diffusé précise la date envisagée pour le premier tour. Celui-ci doit ' +
                'se tenir, au plus tard, le quatre-vingt-dixième jour suivant la diffusion.',
                'Réduction de 30% sur le prix par kWh HTT de l’électricité pendant les Heures Creuses ' +
                'en semaine (du lundi au vendredi, 8 heures par jour définies par votre gestionnaire de réseau Enedis), ' +
                'et tout le week-end par rapport au prix du kWh HTT des Heures Pleines ' +
                '(Les Heures Creuses Weekend sont du vendredi minuit au dimanche minuit. ' +
                'Les Heures Creuses Semaine sont fixées localement par le Distributeur en fonction des conditions ' +
                'd’exploitation du réseau. Il s’agit de 8 heures par jour. ' +
                'Elles sont mentionnées sur votre facture. Les Heures Pleines sont ' +
                'toutes les heures autres que des Heures Creuses.)',
                'Le propriétaire d\'un logement soumis à la loi de 1948 peut décider de ' +
                'faire cesser ce régime pour relouer au prix du marché, dans certains cas (selon la' +
                ' catégorie du logement et les ressources du locataire). Il doit pour cela signer ' +
                'avec le locataire un bail de sortie progressive de la loi de 1948, d\'une durée de 8' +
                ' ans. Ensuite, le propriétaire et le locataire pourront signer un nouveau bail, ' +
                'avec un loyer correspondant au prix du marché.'
            ]
        };
    },
    mounted: function() {
        const refEl = this.$refs.mainContent.$el;
        const self = this;
        const resizeObserver = new ResizeObserver(function() {
            self.mainContentHeight = refEl.clientHeight;
        });
        resizeObserver.observe(refEl);
    },
    methods: {
        handleTabChange: function (tab) {
          this.question = this.questionsPerTab[tab];
          this.paragraph = this.paragraphsPerTab[tab];
        },
        addAnnotation: function () {
            if (!this.question || !this.paragraph) {
                return;
            }
            const queryToSend = {
                'question': this.question,
                'paragraph': this.paragraph,
            };
            const path = `${process.env.VUE_APP_BACK_URL}`;
            this.loading = true;
            axios.defaults.headers.common = {
                'X-ILLUIN-API-Key': process.env.VUE_APP_ILLUIN_NLU_API_KEY,
            };

            axios.post(path, queryToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    this.qaResults = res.data.results;
                    this.loading = false;
                    const id = this.listOfQuestions.length;
                    const qa = {
                        id,
                        question: this.question,
                        answer: this.qaResults[0].text ||'NO_ANSWER',
                        proba: Math.round(this.qaResults[0].softmax_confidence * 100)
                    };
                    this.listOfQuestions.splice(0, 0, qa);
                    if (this.listOfQuestions.length === 1) {
                        this.mainContentHeight = this.$refs.mainContent.$el.clientHeight;
                    }
                });
        }
    },
};
