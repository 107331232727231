export default {
    name: 'QAExplorerParagraph',
    props: {
        value: {
            type: String
        }
    },
    data () {
        return {
            paragraph: this.value
        }
    },
    methods: {
        handleInput () {
            this.$emit('input', this.paragraph)
        }
    },
    watch: {
        value: function(newValue) {
            this.paragraph = newValue;
        }
    }
}
