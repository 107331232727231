export default {
    name: 'QAAnalysis',
    props: {
        listOfQuestions: {
            type: Array,
        }
    },
    methods: {
        wrapConfidence: function (confidence) {
            if (confidence > 30) {
                return 'Haute';
            }
            if (confidence > 10) {
                return 'Moyenne';
            }
            return 'Faible';
        },
    }
}
